<template>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area vendordashboard">
          <div class="container containerDash">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sidebar-dashboard></sidebar-dashboard>
              </div>

              <div class="col-lg-9">
                <h3>My Vehicles</h3>
                <div v-if="!items.length">
                  <notfound :checkdata="initialLoading"></notfound>
                </div>
                <div class="row" style="background-color: #ededed; padding: 12px 0px" v-else>
                  <div class="col-md-4" v-for="vechlist in items" :key="vechlist.vehId">
                    <div class="">
                      <article class="pdtSliderhome">
                        <div>
                          <div>


                            <img
                              :src="vechlist.coverImage ? (path + '/' + vechlist.coverImage) : vechlist.vehImage1 ? (path + '/' + vechlist.vehImage1) : '/assets/images/Noimage.jpg'"
                              class="img-fluid" style="
                                object-fit: cover;
                                width: 100%;
                                height: 160px;
                              " />

                          </div>
                          <div class="leftright10pad">
                            <h6 class="vehicleListing">
                              {{ vechlist.brandName }} {{ vechlist.modelName }}
                              {{ vechlist.manYear }} <span v-if="vechlist.status == true" class="approvalCls">
                                Approved</span>
                              <span v-else class="notApprovalCls"> Not
                                Approved</span>
                            </h6>
                          </div>
                          <!-- <div class="leftright10pad text-center pdtTileTimer">
                        <span class="">Registration :  {{ vechlist.vehRegNo  }}
                        </span>
                      </div> -->
                          <div class="leftright10pad">
                            <div class="pdtHomelistrow">
                              <div class="pdtHomelistcolleft">Registration</div>
                              <div class="pdtHomelistcolright">
                                {{ vechlist.vehRegNo }}
                              </div>
                            </div>
                            <div class="pdtHomelistrow">
                              <div class="pdtHomelistcolleft">Brand Name</div>
                              <div class="pdtHomelistcolright">
                                {{ vechlist.brandName }}
                              </div>
                            </div>
                            <div class="pdtHomelistrow">
                              <div class="pdtHomelistcolleft">Year Of Make</div>
                              <div class="pdtHomelistcolright">
                                {{ vechlist.manYear }}
                              </div>
                            </div>
                            <div class="pdtHomelistrow">
                              <div class="pdtHomelistcolleft">Price</div>
                              <div class="pdtHomelistcolright" v-if="vechlist.vehPrice == null">
                                ₹ 0
                              </div>
                              <div class="pdtHomelistcolright" v-else>
                                {{ vechlist.vehPrice | currency }}
                              </div>
                            </div>
                            <div v-if="vechlist.auctionStatus" class="herestatus">

                              <p>{{ vechlist.auctionStatus }}</p>
                            </div>

                            <router-link :to="{
                  name: 'UpdateVechDetails',
                  params: { url_key: vechlist.vehId },
                }" class="rlistviewD">
                              View Details
                            </router-link>
                            <!-- <div>
                              <label style="
                                  background-color: green;
                                  margin-top: 8px;
                                  font-size: 14px;
                                  padding: 7px;
                                  width: 100%;
                                  text-align: center;
                                  color: white;
                                  border-radius: 4px;
                                " class="" type="button" for="vechApproval">Request for Auction
                              </label>
                            </div> -->


                            <!-- <div v-if="vechlist.status == true">
                              <label
                                style="
                                  background-color: green;
                                  margin-top: 8px;
                                  font-size: 14px;
                                  padding: 7px;
                                  width: 100%;
                                  text-align: center;
                                  color: white;
                                  border-radius: 4px;
                                "
                                class=""
                                type="button"
                                for="vechApproval"
                                >Approved
                              </label>
                            </div> -->
                            <!-- <div v-else>
                              <label
                                style="
                                  background-color: red;
                                  margin-top: 8px;
                                  font-size: 14px;
                                  padding: 7px;
                                  width: 100%;
                                  text-align: center;
                                  color: white;
                                  border-radius: 4px;
                                "
                                class=""
                                type="button"
                                for="vechApproval"
                              >
                                Not Approved</label
                              >
                            </div> -->
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>

                </div>
                <div v-if="items.length" class="overflow-auto">
                  <div class="mt-3">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" pills
                      first-number last-number></b-pagination>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import userService from "@/services/user.service";
import Config from "@/config";
export default {
  data() {
    return {
      vechApproval: "",
      perPage: 12,
      currentPage: 1,
      sellervechlists: "",
      path: Config.BASE_URL_IMG,
      initialLoading: true,
      firstImage: "",
      //  :src="imags + sellervechlists.vehImage1"
      // getVehiclesListBySellerId
    };
  },
  mounted() {
    if (this.$store.state.auth.user) {
      this.st = this.$store.state.auth.user[0];
      this.getVehiclesListBySellerId();
    } else {
      this.$router.push("/").catch(() => {
        "myaccount";
      });
    }
  },
  methods: {
    getVehiclesListBySellerId() {
      let id = this.st.sellerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getVehiclesListBySellerId(id)
          .then((response) => {
            this.sellervechlists = response.data.Data;
            this.vechApproval = true;
            this.initialLoading = false;

            //  for (let i = 0; i < array.length; i++) {
            //   const element = array[i];

            //  }
            //   var vehImageArry =
            // console.log(response.data.Data[0]);
          })
          .catch((e) => {
            console.log(e);
            this.initialLoading = false;
          });
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

    items() {
      return this.sellervechlists.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    totalRows() {
      return this.sellervechlists.length;
    },
  },
  created() {
    if (this.loggedIn) {
      if (
        !this.$store.state.auth.user[0].sellerId == "" ||
        !this.$store.state.auth.user[0].sellerId == " "
      ) {
        this.$router.push("/MyVehicleListings").catch(() => {
          "home";
        });
      } else {
        this.$router.push("/").catch(() => {
          "home";
        });
      }
    }
  },
  filters: {
    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      let country = Config.countryCode;
      let crncy = Config.curency;
      let FractionDigits = Config.minFractionDigits;
      const formatter = new Intl.NumberFormat(country, {
        style: "currency",
        currency: crncy,
        minimumFractionDigits: FractionDigits,
      });
      return formatter.format(value);
    },
  },
};
</script>
<style>
.approvalCls {
  float: left;
  position: absolute;
  top: 10px;
  color: green;
  left: 25px;
  background-color: white;
  border-radius: 2px;
  padding: 4px;
}
.notApprovalCls {
  float: left;
  position: absolute;
  top: 10px;
  color: red;
  left: 25px;
  background-color: white;
  border-radius: 2px;
  padding: 4px;
}
.rlistviewD {
  background-color: #0094da;
  color: white;
  padding: 5px;
  width: 100%;
  display: block;
  text-align: center;
  border-radius: 5px;
  margin-top: 5px;
  font-weight: 500;
}

.pdtHomelistrow {
  font-weight: 500;
}

.pdtSliderhome {
  margin: 0px 0px 20px 0px;
}

.leftright10pad {
  padding-bottom: 5px;
}

.pdtHomelistcolright {
  width: 50%;
  text-align: left;
  border-top: 1px solid rgb(199, 199, 199);
  padding-left: 6px;
}

.pdtHomelistcolleft {
  width: 50%;
  text-align: left;
  border-right: 1px solid rgb(199, 199, 199);
  border-top: 1px solid rgb(199, 199, 199);
}
</style>
